const fuelManagementRouter = {
  path: '/fuel-management',
  meta: {
    title: 'FuelManagement',
    icon: 'fuel-management'
  },
  children: [
    // {
    //   path: 'fuel-log',
    //   name: 'FuelLog',
    //   meta: { title: 'FuelLog', icon: 'submenu' },
    //   children: [
    //     {
    //       path: 'overview',
    //       component: () => import('@/views/fuel-management/FuelLog/overview'),
    //       name: 'FuelManagementOverview',
    //       meta: { title: 'Overview', icon: 'subsubmenu' }
    //     },
    //     {
    //       path: 'fuel-consumption-history',
    //       component: () => import('@/views/fuel-management/FuelLog/fuel-consumption-history'),
    //       name: 'FuelConsumptionHistory',
    //       meta: { title: 'FuelConsumptionHistory', icon: 'subsubmenu' }
    //     },
    //     {
    //       path: 'refuel-management',
    //       component: () => import('@/views/fuel-management/FuelLog/refuel-management'),
    //       name: 'RefuelManagement',
    //       meta: { title: 'RefuelManagement', icon: 'subsubmenu' }
    //     }
    //   ]
    // },
    {
      path: 'fuel-price-management',
      component: () => import('@/views/fuel-management/FuelPriceManagement/fuel-price-management'),
      name: 'FuelPriceManagement',
      meta: { title: 'FuelPriceManagement', icon: 'submenu' }
    },
    // {
    //   path: 'fuel-spend',
    //   name: 'FuelSpend',
    //   meta: { title: 'FuelSpend', icon: 'submenu' },
    //   children: [
    //     {
    //       path: 'vehicle',
    //       component: () => import('@/views/fuel-management/FuelSpend/vehicle'),
    //       name: 'FuelSpendVehicle',
    //       meta: { title: 'Vehicle', icon: 'subsubmenu' }
    //     },
    //     {
    //       path: 'driver',
    //       component: () => import('@/views/fuel-management/FuelSpend/driver'),
    //       name: 'FuelSpendDriver',
    //       meta: { title: 'Driver', icon: 'subsubmenu' }
    //     }
    //   ]
    // },
  ]
}

export default fuelManagementRouter
