import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { usePermissionStore } from '@/store/store'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

import router from '@/router'
import { getToken } from '@/utils/auth'
// import { ElNotification } from "element-plus"
// import i18n from "@/local/i18n.js"

router.beforeEach(async (to, from, next) => {
    // start progress bar
    NProgress.start()

    const permission = usePermissionStore()

    const hasToken = getToken()
    if (hasToken) {
        if (to.path === '/login') {
            next({ path: '/' })
            NProgress.done()
        } else {
            const access = permission.routes
            if (access.length > 0) {
                next()
            } else {
                // const permissions = await permission.getPermission()
                const accessRoutes = permission.generateRoutes()
                if (accessRoutes.length > 0) {
                    // dynamically add accessible routes
                    accessRoutes.forEach(route => {
                        router.addRoute(route)
                    })
                    router.addRoute({
                        path: '/:pathMatch(.*)',
                        redirect: '/404'
                    })
                    // await router.replace(router.currentRoute.value.fullPath)
                }
                next({ ...to, replace: true })
            }
        }
    } else {
        if (to.meta.bypass) {
            next()
        } else {
            next('login')
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
