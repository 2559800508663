import { defineStore } from 'pinia'
import { constantRoutes, asyncRoutes } from '@/router'

export const useAppStore = defineStore('app', {
  state: () => ({
    sidebar: localStorage.getItem("sidebarStatus") || true,
    device: 'desktop'
  }),
  actions: {
    toggleSideBar() {
      this.sidebar = !this.sidebar
      if (this.sidebar) {
        localStorage.setItem("sidebarStatus", "true")
      } else {
        localStorage.setItem("sidebarStatus", "false")
      }
    },
    closeSideBar() {
      localStorage.setItem("sidebarStatus", "false")
      this.sidebar = false
    },
    toggleDevice(val) {
      this.device = val
    }
  },
})

export const useBreadcrumbStore = defineStore('breadcrumb', {
  state: () => ({
    category: '',
    report: ''
  }),
  actions: {
    update(val) {
      this.category = val.category
      this.report = val.report
    }
  }
})

export const usePermissionStore = defineStore('permission', {
  state: () => ({
    roles: 'admin',
    routes: [],
  }),
  actions: {
    generateRoutes() {
      this.routes = constantRoutes.concat(asyncRoutes)
      return this.routes
    }
  }
})