import { createApp } from 'vue'
import App from './App.vue'
// vue router
import router from './router'
// permission router before each
import '@/permission'
// pinia
import { createPinia } from 'pinia'
const pinia = createPinia()
// tailwind
import './styles/tailwind.scss'
// system styles
import './styles/styles.scss'
// animate css
import '@/styles/animate.css'
// i18n international language
import I18n from '@/local/i18n'
// Element Plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
// fontawesome
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)
dom.watch()
// svg sprite loader
import SvgIcon from '@/components/SvgIcon/index.vue'
const req = require.context('@/icons/svg', false, /.svg$/)
req.keys().map(req)
// button wave effect
import VWave from 'v-wave'
// vue leaflet css
import 'leaflet/dist/leaflet.css'
import '@/styles/leaflet.css' // vue leaflet cluster 
// google map extension
import VueGoogleMaps from 'vue-google-maps-community-fork'
// highcharts
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import hcMore from "highcharts/highcharts-more"
import exportingInit from 'highcharts/modules/exporting'
hcMore(Highcharts)
Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
})
// Initialize exporting module
exportingInit(Highcharts)


const app = createApp(App)
app
  .use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
        libraries: ['places', 'drawing']
    },
    autobindAllEvents: false, // Add this to enable the events
  })
  .use(router)
  .use(pinia)
  .use(I18n)
  .use(ElementPlus)
  .use(HighchartsVue, { highcharts: Highcharts })
  .use(VWave)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('svg-icon', SvgIcon)
  .mount('#app')
