const TokenKey = 'TrackVisionToken'
const UserKey = 'TrackVisionUser'

export function getToken() {
    if (localStorage.getItem(TokenKey)) {
        return localStorage.getItem(TokenKey)
    } else {
        return
    }
}

export function getUser() {
    if (localStorage.getItem(UserKey)) {
        return JSON.parse(localStorage.getItem(UserKey))
    } else {
        return
    }
}

export function setToken(token, user) {
    localStorage.setItem(TokenKey, token)
    localStorage.setItem(UserKey, user)
}

export function removeToken() {
    localStorage.removeItem(TokenKey)
    localStorage.removeItem(UserKey)
}