const userSettingsRouter = {
  path: '/user-settings',
  hidden: true,
  meta: {
    title: 'UserSettings',
  },
  children: [
    {
      path: 'user-profile',
      component: () => import('@/views/UserSettings/UserProfile/user-profile'),
      name: 'UserProfile',
      meta: {
        title: 'UserProfile',
      }
    },
    {
      path: 'help-center',
      component: () => import('@/views/UserSettings/HelpCenter/help-center'),
      name: 'HelpCenter',
      meta: {
        title: 'HelpCenter',
      }
    },
  ]
}

export default userSettingsRouter